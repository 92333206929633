import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatFooter from "./ChatFooter";

const ChatBody = ({
  messages,
  typingStatus,
  lastMessageRef,
  status,
  message,
  messagesInput,
  setMessagesInput,
  setStatus,
}) => {
  const navigate = useNavigate();

  // get data from API using fetch
  const [temaDelDia, setTemaDelDia] = useState({
    id: 2,
    created_at: "2023-04-21 20:35:11",
    updated_at: "2023-04-21 20:35:11",
    date: "2023-04-21",
    title: "N/A",
  });
  useEffect(() => {
    fetch("https://apielagite.afterprojects.co/api/v1/topic")
      .then((response) => response.json())
      .then((data) => setTemaDelDia(data[0] ? data[0] : temaDelDia))
      .catch((error) => {
        console.error("Error fetching topic:", error);
        setTemaDelDia(temaDelDia);
      });
  }, []);

  return (
    <>
      <header className="chat__mainHeader">
        <div
          className={status === "SI" ? "heartbit blink" : "heartbit_off blink"}
        ></div>
        <div className="titulo_agite">
          {" "}
          {status === "SI" ? "El Agite! Online" : "El Agite! Offline"}
        </div>
      </header>

      <div className="message__container">
        <h2> {"< HOY SE DICE >"} </h2>
        <div className="temadelDia">{temaDelDia?.title}</div>
        <div>
          <ChatFooter
            messagesInput={messagesInput}
            setMessagesInput={setMessagesInput}
            setStatus={setStatus}
          />
        </div>
        {/* <mark
          className="alternativetext"
          style={{
            display: "block",
            margin: "30px 30px 5px 30px",
            fontSize: "22px",
            textAlign: "left",
          }}
        >
          {"{ BB, usted esta más"}
        </mark>
        <mark
          className="alternativetext"
          style={{
            display: "block",
            margin: "5px 30px",
            fontSize: "22px",
            textAlign: "left",
          }}
        >
          {"que comer"}
        </mark>
        <mark
          className="alternativetext"
          style={{
            display: "block",
            margin: "5px 30px",
            fontSize: "22px",
            textAlign: "left",
          }}
        >
          {"con hambre. "}
        </mark>
        <mark
          className="alternativetext"
          style={{
            display: "block",
            margin: "5px 30px",
            fontSize: "22px",
            textAlign: "left",
          }}
        >
          {"Att: El muelas }"}
        </mark> */}
        {/* texto */}
        {/* <mark
          className="alternativetext"
          style={{
            display: "block",
            margin: "30px 55px 5px 55px",
            fontSize: "22px",
            textAlign: "left",
          }}
        >
          {messagesInput}
        </mark> */}
      </div>
    </>
  );
};

export default ChatBody;
