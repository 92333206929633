import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import fueguito from "../styles/images/fueguito.gif";

import "./home.css";

const Home = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("userName", userName);
    //sends the username and socket ID to the Node.js server
    // socket.emit('newUser', { userName, socketID: socket.id });
    navigate("/chat");
  };

  return (
    <>
      <div className="regularfondo">
        <form className="home__container" onSubmit={handleSubmit}>
          <div className="home__header">
            <img width={120} src={fueguito} />
            <h1>
              ES MOMENTO <br />
              <small>DEL</small> <span class="shadow">AGITE</span>{" "}
              <small>MOR</small>
            </h1>
          </div>
          <h2 className="home__header">A.K.A. / ALIAS</h2>
          <input
            type="text"
            minLength={1}
            name="username"
            id="username"
            className="username__input"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <button className="home__cta">ENTRAR</button>
        </form>
      </div>
    </>
  );
};

export default Home;
