import React, { useEffect, useState, useRef } from "react";
import ChatBar from "./ChatBar";
import ChatBody from "./ChatBody";
import ChatDisabled from "./ChatDisabled";
import rayito from "../styles/images/rayito.gif";

const ChatPage = ({ socket }) => {
  const [messages, setMessages] = useState([]);
  const [messagesInput, setMessagesInput] = useState(
    "BB, usted esta más buena que comer con hambre. Att: El muelas"
  );
  const [typingStatus, setTypingStatus] = useState("");
  const lastMessageRef = useRef(null);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // create set interval to update data every 5 seconds
  const [status, setStatus] = useState("SI");
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetch('https://apielagite.afterprojects.co/api/v1/status')
  //       .then(response => response.json())
  //       .then(data => setStatus(data[0].isOn));

  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="chat regularfondo">
      <ChatBar />
      <div className="chat__main">
        {status === "SI" ? (
          <>
            <ChatBody
              messages={messages}
              typingStatus={typingStatus}
              lastMessageRef={lastMessageRef}
              status={status}
              messagesInput={messagesInput}
              setMessagesInput={setMessagesInput}
              setStatus={setStatus}
            />

            <div className="claim">
              <div>
                <img width={100} src={rayito} />

              </div>
              <div>
                <code>
                  {"#ELSOTANODELPERREO"} <br />
                </code>

              </div>
            </div>
            {/* <ChatFooter
              messagesInput={messagesInput}
              setMessagesInput={setMessagesInput}
            /> */}
          </>
        ) : (
          <ChatDisabled />
        )}
      </div>
    </div>
  );
};

export default ChatPage;
