import React, { useEffect, useState } from "react";

const ChatBodyFs = ({ messages, typingStatus, lastMessageRef }) => {
  const [whiteScreen, setWhiteScreen] = useState(true);
  // get data from API using fetch
  const [message, setMessage] = useState({
    id: "N/A",
    created_at: "N/A",
    updated_at: "N/A",
    date: "N/A",
    topic: "N/A",
    user: "N/A",
    message: "N/A",
    showed: "N/A",
  });

  const urlGifs = [
    "",
    "https://webagite.afterprojects.co/gifs/01.gif",
    "https://webagite.afterprojects.co/gifs/02.gif",
    "https://webagite.afterprojects.co/gifs/03.gif",
    "https://webagite.afterprojects.co/gifs/04.gif",
    "https://webagite.afterprojects.co/gifs/05.gif",
    "https://webagite.afterprojects.co/gifs/06.gif",
    "https://webagite.afterprojects.co/gifs/07.gif",
    "https://webagite.afterprojects.co/gifs/08.gif",
    "https://webagite.afterprojects.co/gifs/09.gif",
  ]
  const [gif, setGif] = useState(urlGifs[1]);
  useEffect(() => {
    fetch("https://apielagite.afterprojects.co/api/v1/getmessage")
      .then((response) => response.json())
      .then((data) => setMessage(data[0]));

    const interval = setInterval(() => {
      fetch("https://apielagite.afterprojects.co/api/v1/getmessage")
        .then((response) => response.json())
        .then((data) => setMessage(data[0]));
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const newInterval = setInterval(() => {
      if (whiteScreen) {
        setWhiteScreen(false);
      } else {
        setWhiteScreen(true);
      }
      console.log("ws ", whiteScreen);
    }, 15000);
    return () => clearInterval(newInterval);
  }, [whiteScreen]);


  useEffect(() => {
    const gifInterval = setInterval(() => {
      const alt = Math.floor((Math.random() * (9)) + 1);
      setGif(urlGifs[alt]);
      console.log("gif ", gif);
    }, 5000);
    return () => clearInterval(gifInterval);
  }, [gif]);

  return (
    <>
      {whiteScreen ? (
        <div className="message__container_fs">
          <img src={gif} style = {{ position:'fixed',top:'0px',left:'0px',width:'100%',height:'100%' }} />
        </div>
      ) : (
        <div className="message__container_fs">
          <div className="message__chats" key={message.id}>
            <div className="message__sender">
              <p
                style={{
                  fontWeight: "bold",
                  backgroundColor: "rgba(6, 6, 6, 0.35)",
                  padding: "10px 20px",
                }}
              >
                {message.message}
              </p>
            </div>
            <p className="sender__name"><span>Att:</span> {message.user}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBodyFs;
