import React from 'react';
import { useNavigate } from 'react-router-dom';
import fueguito from "../styles/images/fueguito.gif";

const ChatDisabled = ({ messages, typingStatus, lastMessageRef, status }) => {
    const navigate = useNavigate();

  const handleLeaveChat = () => {
    localStorage.removeItem('userName');
    navigate('/');
    window.location.reload();
  };



  return (
    <>
      <header className="chat__mainHeader">
        <div className={status==='Si'?'heartbit blink':'heartbit_off blink'}></div>
        <div className="titulo_agite"> {status==='Si'?'El Agite! Online':'El Agite! Offline'}</div>
      </header>


      <div className="message__container">
        <img width={120} src={fueguito} />
        <h1 style={{marginTop:'20px'}}>
          <span class="shadow">QUE CHIMBA</span>
        </h1>
        <h2 style={{marginTop:'50px', marginBottom:'50px'}}> PENDIENTES <br/>CON LAS PANTALLAS </h2>
        <mark className="alternativetext">{' < LEY DEL AGITE >'}</mark>
        <h2 style={{marginTop:'30px', marginBottom:'50px'}}> LO QUE SE DICE <br/>EN EL AGITE <br/>QUEDA EN EL AGITE </h2>
        <button className="leaveChat__btn" onClick={handleLeaveChat} >
          SALIR
        </button>
      </div>
    </>
  );
};

export default ChatDisabled;