import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './components/Home';
import ChatPage from './components/ChatPage';
import Lock from './components/Lock';
import Admin from './components/Admin';
import Messages from './components/Messages';
import './components/styles/styles.css';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/chat" element={<ChatPage />}> </Route>
          <Route path="/lock" element={<Lock />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/messages" element={<Messages />}></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;