import React, { useEffect, useState, useRef } from 'react';
import ChatBodyFullScreen from '../ChatPage/ChatBodyFs';

import '../ChatPage/chatfs.css';

const Messages = () => {
  
  return (
    <div className="chat chat_fs">
      <div className="chat__main">
        <ChatBodyFullScreen />
        </div>
    </div>
  );
};

export default Messages;