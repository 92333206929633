import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import axios
import axios from "axios";

const ChatFooter = ({ socket, setMessagesInput, setStatus }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [activeForm, setActiveForm] = useState(true);

  // get data from API using fetch
  const [temaDelDia, setTemaDelDia] = useState({
    id: 2,
    created_at: "2023-04-21 20:35:11",
    updated_at: "2023-04-21 20:35:11",
    date: "2023-04-21",
    title: "N/A",
  });
  useEffect(() => {
    fetch("https://apielagite.afterprojects.co/api/v1/topic")
      .then((response) => response.json())
      .then((data) => setTemaDelDia(data[0]));
  }, []);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim() && localStorage.getItem("userName")) {
      //use axios to send message to backend
      axios
        .post("https://apielagite.afterprojects.co/api/v1/savemessage", {
          message: message,
          user: localStorage.getItem("userName"),
          topic: temaDelDia.title,
        })
        .then(function (response) {
          console.log(response);
          setActiveForm(false);
          setStatus("NO");
        });
    }
  };

  const handleLeaveChat = () => {
    localStorage.removeItem("userName");
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      {activeForm ? (
        <>
          <div className="chat__footer">
            <form className="form" onSubmit={handleSendMessage}>
              <textarea
                type="text"
                autoFocus
                placeholder="< Gata Fiera >"
                className="message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setMessagesInput(e.target.value);
                }}
              ></textarea>
              {/* <button className="sendBtn">Enviar</button> */}
              <button className="home__cta">Enviar</button>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="chat__footer">
            <h2 style={{ textAlign: "center" }}> Gracias por Agitarte! </h2>
          </div>
          <button className="leaveChat__btn" onClick={handleLeaveChat}>
            Salir!
          </button>
        </>
      )}
    </>
  );
};

export default ChatFooter;
